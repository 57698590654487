import 'lazysizes';
$().ready(function(){
    var html = document.getElementsByTagName('html')[0];
    if(html){
        html.classList.remove("no-js");
        html.classList.add("js");
    }
    setupSlideMenu();
    if ($('form').length){
        $('form').each(function(){
            var validator = $(this).validate();
            // calculator forms don't have float labels
            if ($(".js-float-wrap").length){
                $(this).addClass('floatlabel-active');
                $(this).find('.js-float-wrap').FloatLabel();
            }
            //sheep calculator form
            if ($(this).attr('id') == "roi"){
                 // check if valid on click
                $("button#calc-roi").on("click", function(e){
                    e.preventDefault(); // don't send
                    if (validator.form()){ // returns true if valid
                        roiSheepCalculator();
                    } else {
                        $("#roi-result").hide();
                    }
                });
            }
            // cattle calculator form
            if ($(this).attr('id') == "cattle-trade"){
                // check if valid on click
                $("button#calc-cattle").on("click", function(e){
                    e.preventDefault(); // don't send
                    if (validator.form()){ // returns true if valid
                        cattleTradeCalculator();
                    } else {
                        $("#roi-result").hide();
                    }
                });
            }
        });
    }
    // home banner questions
    processBanner();

    //activates content for the submenu & staff lists
    $(".swap-list a.switch").on("click", function(){
        var data_url = $(this).data("url");
        if (data_url){ // same page nav
            // clear active
            $(this).parent().siblings().removeClass("active");
            $(".swap-list .page-body div.content").removeClass("active");
            // set active
            $(this).parent().addClass("active");
            $("div.content#"+data_url).addClass("active");
            // scroll to the active div
            var pos = $("div.content#"+data_url).offset().top;
            var head_height = $("header").outerHeight() + 20;
            if (pos){
                pos = pos - head_height;
                $('html, body').animate({scrollTop: pos}, 1000);
            }
        }
    });
});
$(window).on("load", function(){
    // after images have loaded
    setSwapHeight();
});
var delay;
$(window).on("resize", function(){
    clearTimeout(delay);
    delay = setTimeout(function(){setSwapHeight();}, 100);
});
var scrollTimer = null;
$(window).on("scroll", function(){
    clearTimeout(scrollTimer);
    scrollTimer = setTimeout(function(){
        if($(window).scrollTop() > 0){
            if (!$("header").hasClass("sticky")){
                var stickyClone = $("header").clone().addClass("sticky").insertAfter("header");
                var stickyH = stickyClone.outerHeight();
                stickyClone.remove();
                $("body").css("padding-top", stickyH+"px");
                $("header").addClass("sticky");
            }
        } else {
            $("body").css("padding-top", "0px");
            $("header").removeClass("sticky");
        }
    }, 100);
});

function setupSlideMenu(){
    $('.mobile-menu, .slide-menu h3').click(function(){
        $("body").toggleClass('slide-menu-active');
    });
    $(".slide-menu").on('click','.expand',function(e) {
        e.stopPropagation();
        $(this).closest('li').toggleClass('expand-active');
    });
    $(".slide-menu li").each(function(index, element) {
        var $el = $(element);
        if ($el.find('ul').length > 0) {
            var expander = $('<span class="expand"><i class="nav-arrow"></i></span>');
            $el.append(expander);
            expander.click(function(e) {
                e.stopPropagation();
                $el.toggleClass('expand-active');
            });
        }
    });
}

function setSwapHeight(){
    // set the max height so the content area doesn't jump around
    var maxH = 0;
    $(".swap-list .page-body .content").each(function(){
        var outerH = Math.ceil($(this).outerHeight());
        maxH =  (outerH > maxH)? outerH : maxH;
    });
    $(".swap-list .page-body .content").css("min-height", maxH+"px");
}

function processBanner(){
    var banner = document.querySelector(".banners-home .banner");
    if (banner){
        // Open the form - with the rest of the questions
        $(".answer span.open").on("click", function(){
            $("div.banners-home").addClass("form-open");
            // get form natural height
            var form_wrapper = document.querySelector("div.questions");
            var number_of_inputs = $("div.questions form input").length;
            /* allow room for input error labels */
            var height = Math.ceil(form_wrapper.scrollHeight) + (30 * number_of_inputs);
            form_wrapper.style.maxHeight = height+"px";
        });
    }
}

function roiSheepCalculator(){
    // get inputs
    var ewes = $("input#ewes").val();
    var ewe_price = parseFloat($("input#ewe-price").val());
    var ewe_cost = parseFloat($("input#ewe-cost").val());
    var weaned = $("input#lambs-weaned").val();
    var bench_weaned = $("input#bench-percent").val();
    var lamb_price = parseFloat($("input#lamb-price").val());
    var lamb_rating = $("input#lamb-rating").val();
    var twinner_rating = $("input#twinner-rating").val();
    var mixed_rating = $("input#mixed-rating").val();

    // weaning percentage calculations
    var bench_decimal = bench_weaned / 100;
    var weaned_percentage = Math.round((weaned / ewes) * 100);
    var ewes_required = Math.round(weaned / bench_decimal);
    var ewes_additional = ewes - ewes_required;
    var ewe_surplus = ewes_additional * ewe_price;
    // outputs - format functions round numbers
    $("#bench-weaned-percent").html(bench_weaned);
    $("#wean-percent").html(weaned_percentage);
    $("#ewes-required").html(formatThousands(ewes_required));
    $("#ewes-additional").html(formatThousands(ewes_additional));
    $("#ewes-surplus").html(formatThousands(ewe_surplus));

    // DSE
    var target_lambs = weaned * lamb_rating;
    var target_ewes = ewes_required * twinner_rating;
    var target_total = target_lambs + target_ewes;
    var lamb_dse1 = weaned * lamb_rating;
    var ewe_dse1 = ewes * mixed_rating;
    var total_dse1 = lamb_dse1 + ewe_dse1;
    //ouputs - format functions round numbers
    $("#target-lambs").html(formatThousands(target_lambs));
    $("#target-ewes").html(formatThousands(target_ewes));
    $("#target-total").html(formatThousands(target_total));
    $("#lamb-dse1").html(formatThousands(lamb_dse1));
    $("#ewe-dse1").html(formatThousands(ewe_dse1));
    $("#total-dse1").html(formatThousands(total_dse1));

    // what if
    var half_increase = ewes_additional * 0.5;
    var performing_new = ewes - ewes_additional + half_increase;
    var weaned_new = performing_new * bench_decimal;
    //outputs - format functions round numbers
    $("#total-ewe").html(formatThousands(ewes));
    $("#ewe-under").html(formatThousands(ewes_additional));
    $("#ewe-increase").html(formatThousands(half_increase));
    $("#performing-new").html(formatThousands(performing_new));
    $("#weaned-new").html(formatThousands(weaned_new));

    // DSE revised - round down to whole animals
    var lamb_dse2 = Math.round(weaned_new * lamb_rating);
    var ewe_dse2 = Math.round(performing_new * twinner_rating);
    var total_dse2 = Math.round(lamb_dse2 + ewe_dse2);
    var lamb_diff = lamb_dse2 - lamb_dse1;
    var ewe_diff = ewe_dse2 - ewe_dse1;
    var total_diff = total_dse2 - total_dse1;
    // add positive sign if result is positive, minus taken care of
    var l_pos = (Math.sign(lamb_diff) > 0)? "+" : "";
    var e_pos = (Math.sign(ewe_diff) > 0)? "+" : "";
    var t_pos = (Math.sign(total_diff) > 0)? "+" : "";
    // outputs
    $("#lamb-dse2").html(formatThousands(lamb_dse2)); $("span#lamb-dse-diff").html(l_pos + formatThousands(lamb_diff));
    $("#ewe-dse2").html(formatThousands(ewe_dse2)); $("span#ewe-dse-diff").html(e_pos + formatThousands(ewe_diff));
    $("#total-dse2").html(formatThousands(total_dse2)); $("span#total-dse-diff").html(t_pos + formatThousands(total_diff));

    // roi
    var increased_productivity = (weaned_new - weaned) * lamb_price;
    var reduced_cost = half_increase * ewe_cost;
    var potential_profit = increased_productivity + reduced_cost;
    //outputs
    $("#increased-productivity").html(formatThousands(increased_productivity));
    $("#reduced-cost").html(formatThousands(reduced_cost));
    $("#potential-profit").html(formatThousands(potential_profit));

    $("#roi-result").show();
    var pos = $("#roi-result").offset().top;
    if (pos){
        // scroll to the result
        $("html, body").animate({ scrollTop: pos }, 1000);
    }
}

function cattleTradeCalculator(){
    // get inputs
    var head = $("input#cattle").val();
    var dse_rating = $("input#dse-rating").val();
    var avg_daily_gain = parseFloat($("input#avg-daily-gain").val());
    var buy_price =  parseFloat($("input#buy-price").val());
    var purchase_weight = $("input#purchase-weight").val();
    var sell_price =  parseFloat($("input#sell-price").val());
    var sale_weight = $("input#sale-weight").val();
    var overhead_total =  parseFloat($("input#overheads-total").val());
    var yrly_cost = parseFloat($("input#finance-costs").val());
    var cost_per_head = parseFloat($("input#costs-per-head").val());
    var interest_rate = parseFloat($("input#interest-rate").val());

    //outcomes calculations
    var interest_decimal = interest_rate / 100;
    var kg_per_head = sale_weight - purchase_weight;
    var days_until_weight = Math.round(kg_per_head / avg_daily_gain);
    var interest_per_head = (buy_price * purchase_weight)*(interest_decimal) * kg_per_head / 365;
    var total_cost_per_head = (buy_price * purchase_weight) + cost_per_head + interest_per_head;
    var cop_per_kg = ((cost_per_head * head) + overhead_total)/(head * kg_per_head);
    var cop_with_interest = ((cost_per_head * head + yrly_cost) + overhead_total)/(head * kg_per_head);
    // output values - note format functions round values
    $("#kg-per-head").html(kg_per_head);
    $("#days-required").html(days_until_weight);
    $("#interest-per-head").html(formatThousands(interest_per_head.toFixed(2)));
    $("#cost-per-head").html(formatThousands(total_cost_per_head));
    $("#cop-per-kg").html(cop_per_kg.toFixed(2));
    $("#cop-with-interest").html(cop_with_interest.toFixed(2));

    //profit calculations
    var gross_profit_dollars = (sell_price * sale_weight) - (buy_price * purchase_weight);
    var gross_profit_percent = Math.round((gross_profit_dollars / (sell_price * sale_weight)) * 100);
    var gross_margin_dollars = gross_profit_dollars - cost_per_head - interest_per_head;
    var gross_margin_percent = Math.round((gross_margin_dollars / gross_profit_dollars) * 100);
    var gross_margin_head_per_day = gross_margin_dollars / days_until_weight;
    var gross_margin_dse = gross_margin_dollars / dse_rating;
    var total_gross_margin = head * gross_margin_dollars;
    // output values - format functions round values
    $("#gross-profit-percent").html(gross_profit_percent);
    $("#gross-profit-dollar").html(formatThousands(gross_profit_dollars));
    $("#gross-margin-percent").html(gross_margin_percent);
    $("#gross-margin-dollar").html(formatThousands(gross_margin_dollars));
    $("#gross-margin-head-day").html(gross_margin_head_per_day.toFixed(2));
    $("#gross-margin-dse").html(formatThousands(gross_margin_dse));
    $("#total-gross-margin").html(formatThousands(total_gross_margin));

    $("#cattle-result").show();
    var pos = $("#cattle-result").offset().top;
    if (pos){
        // scroll to the result
        $("html, body").animate({ scrollTop: pos }, 1000);
    }
}

function formatThousands(number){
    var rounded = Math.round(number);
    var num_str = new Intl.NumberFormat('en-AU').format(rounded);
    return num_str;
}

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener('lazybeforeunveil', function(e){
    var container = e.target;
    var bgUrl = container.dataset.bgUrl;
    if (bgUrl) {
        container.style.backgroundImage = "url('" + bgUrl + "')";
        delete container.dataset.bgUrl;
    }
});